// import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
// import { useEffect, useState } from "react";
// import { MdClose } from "react-icons/md";
// import ReactCrop, { makeAspectCrop, centerCrop } from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import { ModalItemsContainer } from "components/CustomModal/Styles";
// import { useModal } from "contexts/modalContext";
// import { IMAGE_UPLOAD_SIZE } from "constants/sizes";

// const ImageCrop = ({ src, isCropOn = false, onChange }) => {
//   const { toggleModal } = useModal();
//   const [crop, setCrop] = useState(null);
//   const [image, setImage] = useState(null);
//   const [originalFile, setOriginalFile] = useState(null);
//   const [sizeErr, setSizeErr] = useState(false);

//   useEffect(() => {
//     if (src.file) {
//       setOriginalFile(src.file);
//     }
//   }, [src.file]);

//   const onImageLoad = (e) => {
//     const img = e.currentTarget;
//     setImage(img);

//     // Center crop with 16:9 aspect ratio
//     const initialCrop = centerCrop(
//       makeAspectCrop(
//         { unit: "px", width: img.naturalWidth * 0.8 }, // Start with 80% width
//         16 / 9,
//         img.naturalWidth,
//         img.naturalHeight
//       ),
//       img.naturalWidth,
//       img.naturalHeight
//     );
//     setCrop(initialCrop);
//   };

//   const getCroppedImage = async (fileType) => {
//     if (!originalFile || !crop) {
//       throw new Error("Invalid file or crop settings.");
//     }

//     const canvas = document.createElement("canvas");
//     const scaleX = image.naturalWidth / image.width;
//     const scaleY = image.naturalHeight / image.height;

//     canvas.width = crop.width;
//     canvas.height = crop.height;
//     const ctx = canvas.getContext("2d");

//     ctx.drawImage(
//       image,
//       crop.x * scaleX,
//       crop.y * scaleY,
//       crop.width * scaleX,
//       crop.height * scaleY,
//       0,
//       0,
//       crop.width,
//       crop.height
//     );

//     return new Promise((resolve) => {
//       canvas.toBlob((blob) => {
//         if (blob) {
//           const croppedFile = new File([blob], originalFile.name, { type: fileType });
//           resolve(croppedFile);
//         } else {
//           resolve(null);
//         }
//       }, fileType);
//     });
//   };

//   const handleCrop = async () => {
//     const fileType = originalFile.type;
//     const croppedFile = await getCroppedImage(fileType);

//     if (croppedFile) {
//       if (croppedFile.size > IMAGE_UPLOAD_SIZE) {
//         setSizeErr(true);
//         onChange([{ data_url: 0, file: null }]);
//       } else {
//         setSizeErr(false);
//         const dataUrl = URL.createObjectURL(croppedFile);
//         onChange([{ data_url: dataUrl, file: croppedFile }]);
//       }
//     }
//   };

//   return (
//     <Modal
//       sx={{
//         position: "absolute",
//         top: 0,
//         bottom: 0,
//         right: 0,
//         left: 0,
//         zIndex: 10000,
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//       open={isCropOn}
//     >
//       <ModalItemsContainer
//         sx={{ background: "white", width: "70vw", height: "50vw" }}
//       >
//         <Box
//           sx={{
//             px: 2,
//             pt: 1,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <IconButton
//             onClick={() => toggleModal("modal1")}
//             sx={{
//               alignSelf: "flex-end",
//               position: "absolute",
//               top: "0",
//               right: "0",
//             }}
//           >
//             <MdClose color="black" />
//           </IconButton>
//           <Box sx={{ display: "flex", justifyContent: "center" }}>
//             <ReactCrop
//               crop={crop}
//               onChange={setCrop}
//               aspect={16 / 9}
//             >
//               <img src={src.data_url} alt="crop" onLoad={onImageLoad} />
//             </ReactCrop>
//           </Box>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               width: "50%",
//             }}
//           >
//             <Button
//               variant="outlined"
//               color="error"
//               sx={{ mt: 2, width: "38%" }}
//               onClick={() => toggleModal("modal1")}
//             >
//               Cancel
//             </Button>
//             <Button
//               variant="contained"
//               sx={{ mt: 2, width: "38%" }}
//               onClick={handleCrop}
//             >
//               Next
//             </Button>
//           </Box>
//           {sizeErr && (
//             <Typography mt={3} color="error" variant="body2">
//               The image size exceeds the limit after cropping. Please provide a smaller image.
//             </Typography>
//           )}
//         </Box>
//       </ModalItemsContainer>
//     </Modal>
//   );
// };

// export default ImageCrop;


import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import ReactCrop, { makeAspectCrop, centerCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import { useModal } from "contexts/modalContext";
import { IMAGE_UPLOAD_SIZE } from "constants/sizes";

const ImageCrop = ({ src, isCropOn = false, aspectRatio = 16 / 9, onChange }) => {
  const { toggleModal } = useModal();
  const [crop, setCrop] = useState(null);
  const [image, setImage] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [sizeErr, setSizeErr] = useState(false);

  useEffect(() => {
    if (src.file) {
      setOriginalFile(src.file);
    }
  }, [src.file]);

  const onImageLoad = (e) => {
    const img = e.currentTarget;
    setImage(img);

    // Center crop with the given aspect ratio
    const initialCrop = centerCrop(
      makeAspectCrop(
        { unit: "px", width: img.naturalWidth * 0.8 }, // Start with 80% width
        aspectRatio,
        img.naturalWidth,
        img.naturalHeight
      ),
      img.naturalWidth,
      img.naturalHeight
    );
    setCrop(initialCrop);
  };

  const getCroppedImage = async (fileType) => {
    if (!originalFile || !crop) {
      throw new Error("Invalid file or crop settings.");
    }

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], originalFile.name, { type: fileType });
          resolve(croppedFile);
        } else {
          resolve(null);
        }
      }, fileType);
    });
  };

  const handleCrop = async () => {
    const fileType = originalFile.type;
    const croppedFile = await getCroppedImage(fileType);

    if (croppedFile) {
      if (croppedFile.size > IMAGE_UPLOAD_SIZE) {
        setSizeErr(true);
        onChange([{ data_url: 0, file: null }]);
      } else {
        setSizeErr(false);
        const dataUrl = URL.createObjectURL(croppedFile);
        onChange([{ data_url: dataUrl, file: croppedFile }]);
      }
    }
  };

  return (
    <Modal
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 10000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={isCropOn}
    >
      <ModalItemsContainer
        sx={{ background: "white", width: "70vw", height: "50vw" }}
      >
        <Box
          sx={{
            px: 2,
            pt: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => toggleModal("modal1")}
            sx={{
              alignSelf: "flex-end",
              position: "absolute",
              top: "0",
              right: "0",
            }}
          >
            <MdClose color="black" />
          </IconButton>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              aspect={aspectRatio}
            >
              <img src={src.data_url} alt="crop" onLoad={onImageLoad} />
            </ReactCrop>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{ mt: 2, width: "38%" }}
              onClick={() => toggleModal("modal1")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, width: "38%" }}
              onClick={handleCrop}
            >
              Next
            </Button>
          </Box>
          {sizeErr && (
            <Typography mt={3} color="error" variant="body2">
              The image size exceeds the limit after cropping. Please provide a smaller image.
            </Typography>
          )}
        </Box>
      </ModalItemsContainer>
    </Modal>
  );
};

export default ImageCrop;

