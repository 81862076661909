import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import HandshakeIcon from "@mui/icons-material/HandshakeOutlined";
import KeyIcon from "@mui/icons-material/Key";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendarOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import StarBorderTwoToneIcon from "@mui/icons-material/StarBorderTwoTone";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import { BsCalendar3Event } from "react-icons/bs";
import { FaHome, FaPaperPlane } from "react-icons/fa";
import {
  FiGrid,
  FiShoppingBag,
  FiUser,
  FiUsers,
  FiVolume2,
} from "react-icons/fi";
import { GiSecurityGate } from "react-icons/gi";
import { MdOutlineSecurity, MdSettings } from "react-icons/md";
import { customIconsStyle } from "./layout/Aside/Aside";
import ScheduleOrder from "pages/adminPages/EventDetail/ScheduleOrder";

export const authPages = {
  Login: {
    id: "login",
    text: "Login",
    path: "/login",
    icon: "Login",
  },
  RegisterAs: {
    id: "registerAs",
    text: "RegisterAs",
    path: "/registerAs",
    icon: null,
  },
  ForgotPassword: {
    id: "forgotPassword",
    text: "ForgotPassword",
    path: "/password/new",
    icon: null,
  },
  ResetPassword: {
    id: "resetPassword",
    text: "Reset Password",
    path: "/reset-password",
    icon: null,
  },
  CheckEmail: {
    id: "checkEmail",
    text: "Check Email",
    path: "/check-email",
    icon: null,
  },
  NoPermission: {
    id: "noPermission",
    text: "No Permission",
    path: "/no-permission",
    icon: null,
  },
  Verify: {
    id: "verify",
    text: "verify",
    path: "/verify",
    icon: null,
  },
  VerifySuccess: {
    id: "verifySuccess",
    text: "verify success",
    path: "/verify-success",
    icon: null,
  },
};

export const adminAsidePages = {
  Home: {
    id: "home",
    module: "DASHBOARD",
    text: "Dashboard",
    path: "/",
    icon: <FaHome />,
  },

  Ads: {
    id: "ads",
    module: "IN_APP_AD",
    text: "Advertisement",
    path: "/ads",
    icon: <CampaignOutlinedIcon style={customIconsStyle} />,
  },

  // Invitation: {
  //   id: "invitation",
  //   module: "INVITATION",
  //   text: "Invitations",
  //   path: "/invitation",
  //   icon: <FaPaperPlane />,
  // },

  Events: {
    id: "Events",
    module: "EVENT",
    text: "Events",
    path: "/event",
    icon: <BsCalendar3Event />,
  },

  EventRequests: {
    id: "eventRequests",
    module: "EVENT_REQUEST",
    text: "Event requests",
    path: "/event-requests",
    icon: <FiShoppingBag />,
  },

  EventSettings: {
    id: "eventSettings",
    module: "DROPDOWN_EVENTS",
    text: "Event settings",
    path: "/events",
    dropdown: true,
    icon: <FiGrid />,
    items: {
      EmailTemplates: {
        id: "EmailTemplates",
        module: "EVENT_EMAIL_TEMPLATE",
        text: "Email Templates",
        path: "/event/templates",
        icon: <EmailIcon style={customIconsStyle} />,
      },
      EventSpaces: {
        id: "space_categories",
        module: "EVENT",
        text: "Event Space Category",
        path: "/space-categories",
        icon: <MeetingRoomIcon />,
      },
      Locations: {
        id: "locations",
        module: "EVENT",
        text: "Venue",
        path: "/locations",
        icon: <RoomOutlinedIcon style={customIconsStyle} />,
      },
      Spaces: {
        id: "spaces",
        module: "EVENT",
        text: "Spaces",
        path: "/spaces",
        icon: <WorkspacesOutlinedIcon style={customIconsStyle} />,
      },
      Sponsors: {
        id: "sponsors",
        module: "EVENT_SPONSOR",
        text: "Sponsors",
        path: "/sponsors",
        icon: <HandshakeIcon style={customIconsStyle} />,
      },
    },
  },

  ContactSettings: {
    id: "contactSettings",
    module: "DROPDOWN_CONTACTS",
    text: "Contacts",
    path: "/attendees",
    dropdown: true,
    icon: <GroupsOutlinedIcon style={customIconsStyle} />,
    items: {
      Organizations: {
        id: "organizations",
        module: "ORGANIZATION",
        text: "Organizations",
        path: "/organizations",
        icon: <CorporateFareIcon style={customIconsStyle} />,
      },
      Attendees: {
        id: "attendees",
        module: "CONTACT",
        text: "Contacts",
        path: "/attendees",
        icon: <PermContactCalendarIcon style={customIconsStyle} />,
      },
      ContactGroup: {
        id: "contactGroup",
        module: "CONTACT_GROUP",
        text: "Contacts group",
        path: "/contacts-group",
        icon: <GroupsOutlinedIcon style={customIconsStyle} />,
      },
    },
  },

  EmailMarketing: {
    id: "emailMarketing",
    module: "DROPDOWN_EMAIL",
    text: "Email marketing",
    path: null,
    dropdown: true,
    icon: <MailOutlineRoundedIcon style={customIconsStyle} />,
    items: {
      CampaignTemplate: {
        id: "campaignTemplate",
        module: "EMAIL_MARKETING",
        text: "Campaign templates",
        path: "/email-marketing/templates",
        icon: <MailOutlineRoundedIcon style={customIconsStyle} />,
      },
      Campaign: {
        id: "campaign",
        module: "EMAIL_MARKETING",
        text: "Campaign",
        path: "/email-marketing/campaign",
        icon: <SendRoundedIcon style={customIconsStyle} />,
      },
    },
  },

  UserSettings: {
    id: "userSettings",
    module: "DROPDOWN_USERS",
    text: "User settings",
    path: "/users",
    dropdown: true,
    icon: <FiUsers />,
    items: {
      Users: {
        id: "users",
        module: "USER",
        text: "Users",
        path: "/users",
        icon: <FiUser />,
      },
      Roles: {
        id: "roles",
        module: "ROLE",
        text: "Roles",
        path: "/role",
        icon: <KeyIcon style={customIconsStyle} />,
      },
      Permissions: {
        id: "permissions",
        module: "PERMISSION",
        text: "Permissions",
        path: "/permissions",
        icon: <RuleIcon style={customIconsStyle} />,
      },
    },
  },

  Security: {
    id: "security",
    module: "DROPDOWN_SECURITY",
    text: "Gates",
    path: null,
    dropdown: true,
    icon: <GiSecurityGate />,
    items: {
      AssignSecurity: {
        id: "AssignSecurity",
        module: "ASSIGN_SECURITY",
        text: "Assign security",
        path: "/event/assign/security",
        icon: <MdOutlineSecurity />,
      },
      Attendance: {
        id: "Attendance",
        module: "ATTENDANCE",
        text: "Attendance",
        path: "/attendance",
        icon: <RemoveRedEyeIcon style={customIconsStyle} />,
      },
      TicketSellers: {
        id: "ticketSellers",
        module: "ASSIGN_TICKET_SELLER",
        text: "Assign Ticket Seller",
        path: "/ticket-sellers",
        icon: <ConfirmationNumberOutlinedIcon style={customIconsStyle} />,
      },
    },
  },

  Settings: {
    id: "settings",
    module: "SETTING",
    text: "Settings",
    path: "/settings",
    icon: <MdSettings />,
  },
};

export const rentalCustomerAsidePages = {
  Home: {
    id: "home",
    text: "Home",
    path: "/",
    icon: <FaHome />,
  },
};

export const page404 = {
  id: "404",
  text: "Page 404",
  path: "/not-found",
  icon: "null",
};

export const InnerPages = {
  ScheduleOrder: {
    path: "/event/invitations/schedule",
  },
  ContactGroupContacts: {
    path: "/contacts-group/contacts",
  },
  EventInvitations: {
    path: "/event/invitations",
  },
  EventBundle: {
    path: "/event/invitations/bundle",
  },
  Profile: {
    path: "/profile",
  },
  SharedEvents: {
    path: "/event/shared",
  },
  PlanOptions: {
    path: "/admin/plans/detail",
  },
  SharedContacts: {
    path: "/attendees/shared",
  },
  AssignSecurity: {
    path: "/event/assign/security",
  },
  Roles: {
    path: "/role",
  },
  Permissions: {
    path: "/permissions",
  },
  CompanyDetail: {
    path: "/admin/companies/detail",
  },
  VendorDetail: {
    path: "/admin/vendors/detail",
  },
  ForeignTransactionHistory: {
    path: "/payments/history2",
  },
  PaymentHistory: {
    path: "/payments/history",
  },

  SubscriptionPaymentHistory: {
    path: "/subscriptionPaymentHistory",
  },
  EventAttendance: {
    path: "/event/attendance",
  },
};

export const publicPages = {
  Subscription: {
    path: "/subscription",
  },
  Checkout: {
    path: "/checkout",
  },
  PaymentRedirect: {
    path: "/payment-redirect",
  },
  EmailVerified: {
    path: "/email-verified",
  },
  Help: {
    path: "/help",
  },
};

export const vendorPages = {
  VendorLogin: {
    path: "/vendors-web/login",
  },
  VendorHome: {
    path: "/vendors-web",
  },
  VendorEventDetail: {
    path: "/vendors-web/event",
  },
  VendorRequests: {
    path: "vendors-web/requests",
  },
  VendorProfile: {
    path: "vendors-web/profile",
  },
  VendorNotifications: {
    path: "vendors-web/notifications",
  },
};
export const userPages = {
  UserLogin: {
    path: "/users-web/login",
  },
  UserRegister: {
    path: "/users-web/register",
  },
  UserForgotPassword: {
    path: "/users-web/forgot-password",
  },
  UserHome: {
    path: "/users-web",
  },
  UserEventDetail: {
    path: "/users-web/event",
  },
  UserTickets: {
    path: "/users-web/tickets",
  },
  // UserTickets: {
  //   path: "users-web/my-tickets",
  // },
  UserProfile: {
    path: "users-web/profile",
  },
  UserNotifications: {
    path: "users-web/notifications",
  },
};

export const servicesPages = {
  ServicesLogin: {
    path: "/services-web/login",
  },
  ServicesHome: {
    path: "/services-web",
  },
  ServicesEventDetail: {
    path: "/services-web/detail",
  },
  ServicesRequests: {
    path: "services-web/requests",
  },
  ServicesProfile: {
    path: "services-web/profile",
  },
  ServicesNotifications: {
    path: "services-web/notifications",
  },
};

export const subscribers = {
  id: "subscribers",
  module: "DROPDOWN_SUBSCRIBERS",
  text: "Admin console",
  path: null,
  dropdown: true,
  icon: <SubscriptionsIcon style={customIconsStyle} />,
  items: {
    Home: {
      id: "home",
      module: "DASHBOARD",
      text: "Dashboard",
      path: "/",
      icon: <FaHome />,
    },
    Ads: {
      id: "ads",
      module: "IN_APP_ADS",
      text: "Advertisement",
      path: "/admin/ads",
      icon: <CampaignOutlinedIcon style={customIconsStyle} />,
    },
    Banks: {
      id: "banks",
      module: "BANK",
      text: "Banks",
      path: "/admin/banks",
      icon: <AccountBalanceIcon style={customIconsStyle} />,
    },
    Categories: {
      id: "categories",
      module: "EVENT_CATEGORY",
      text: "Categories",
      path: "/admin/categories",
      icon: <CategoryOutlinedIcon />,
    },

    Companies: {
      id: "Companies",
      module: "COMPANY",
      text: "Organizers",
      path: "/admin/companies",
      icon: <CorporateFareIcon style={customIconsStyle} />,
    },
    Subscribers: {
      id: "subscribers",
      module: "SUBSCRIBERS",
      text: "Subscribers",
      path: "/subscribers",
      icon: <FiUsers />,
    },
    Plans: {
      id: "Plans",
      module: "PLAN",
      text: "Subscription plans",
      path: "/admin/plans",
      icon: <PaymentIcon style={customIconsStyle} />,
    },
    AdminPaymentHistory: {
      id: "adminPaymentHistory",
      module: "PAYMENT",
      text: "Payment history",
      path: "/admin/payment-history",
      icon: <PaymentsOutlinedIcon style={customIconsStyle} />,
    },
    SponsorTypes: {
      id: "SponsorTypes",
      module: "SPONSOR_TYPE",
      text: "Sponsor types",
      path: "/admin/sponsor-types",
      icon: <StarBorderTwoToneIcon style={customIconsStyle} />,
    },
    Vendors: {
      id: "Vendors",
      module: "VENDOR",
      text: "Vendors",
      path: "/admin/vendors",
      icon: <StorefrontIcon style={customIconsStyle} />,
    },
    ServiceTypes: {
      id: "ServiceTypes",
      module: "SERVICE_TYPE",
      text: "Service Types",
      path: "/admin/service-types",
      icon: <StarBorderTwoToneIcon style={customIconsStyle} />,
    },
    PushNotifications: {
      id: "pushNotifications",
      module: "PUSH_NOTIFICATION",
      text: "Push Notifications",
      path: "/push-notifications",
      icon: <FiVolume2 />,
    },

    Settings: {
      id: "settings",
      module: "SETTING",
      text: "Settings",
      path: "/settings",
      icon: <MdSettings />,
    },
  },
};

export const commonPages = {
  Notifications: { path: "/notifications" },
  SubscriptionExpired: { path: "/subscription-expired" },
};

// Apply route guarding for inner pages too
export const noAccessReq = [
  ...Object.values(commonPages).map((item) => item.path),
  ...Object.values(InnerPages).map((item) => item.path),
  "/no-permission",
  "*",
];

const superAdminPagesInit = [];
for (const key in subscribers.items) {
  superAdminPagesInit.push(subscribers.items[key].path);
}

export const superAdminPages = superAdminPagesInit;
