import {
  CategoriesHeader,
  EventRequestsHeader,
  EventsHeader,
  HomeHeader,
  InvitationHeader,
  OrganizationsHeader,
  ProfileHeader,
  SettingsHeader,
  SharedContactsHeader,
  SharedEventsHeader,
  UsersHeader,
} from "layout/Header/adminPagesHeader";
import AttendeesHeader from "layout/Header/adminPagesHeader/AtendeesHeader";
import AttendanceHeader from "layout/Header/adminPagesHeader/AttendanceHeader";
import CompaniesHeader from "layout/Header/adminPagesHeader/CompaniesHeader";
import ContactGroupContactsHeader from "layout/Header/adminPagesHeader/ContactGroupContactsHeader";
import ScheduleOrderHeader from "layout/Header/adminPagesHeader/ScheduleOrderHeader";
import ContactGroupHeader from "layout/Header/adminPagesHeader/ContactGroupHeader";
import DefaultHeader from "layout/Header/adminPagesHeader/DefaultHeader";
import EmailTemplatesHeader from "layout/Header/adminPagesHeader/EmailTemplatesHeader";
import EventInvitationsHeader from "layout/Header/adminPagesHeader/EventInvitationsHeader";
import PermissionsHeader from "layout/Header/adminPagesHeader/PermissionsHeader";
import PlansHeader from "layout/Header/adminPagesHeader/PlansHeader";
import RolesHeader from "layout/Header/adminPagesHeader/RolesHeader";
import Header from "layout/Header/Header";
import {
  adminAsidePages,
  authPages,
  commonPages,
  InnerPages,
  subscribers,
} from "menu";
// icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import HandShakeIcon from "@mui/icons-material/HandshakeOutlined";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import StarBorderTwoToneIcon from "@mui/icons-material/StarBorderTwoTone";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import CompanyDetailHeader from "layout/Header/adminPagesHeader/CompanyDetailHeader";
import VendorDetailHeader from "layout/Header/adminPagesHeader/VendorDetailHeader";
import { FiUsers } from "react-icons/fi";
import { MdOutlineSecurity } from "react-icons/md";
import EventBundleHeader from "layout/Header/adminPagesHeader/EventBundleHeader";
import EventSpacesHeader from "layout/Header/adminPagesHeader/EventSpacesHeader";

const adminHeaders = [
  {
    path: adminAsidePages.Home.path,
    element: <Header headerBottom={<HomeHeader />} />,
    exact: true,
  },
  // {
  //   path: adminAsidePages.Invitation.path,
  //   element: <Header headerBottom={<InvitationHeader />} />,
  //   exact: true,
  // },
  {
    path: adminAsidePages.Events.path,
    element: <Header headerBottom={<EventsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.ContactSettings.items.Attendees.path,
    element: <Header headerBottom={<AttendeesHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.ContactSettings.items.ContactGroup.path,
    element: <Header headerBottom={<ContactGroupHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.EmailMarketing.items.CampaignTemplate.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader
            name="Campaign Templates"
            icon={MailOutlineRoundedIcon}
          />
        }
      />
    ),
    exact: true,
  },
  {
    path: adminAsidePages.EmailMarketing.items.Campaign.path,
    element: (
      <Header
        headerBottom={<DefaultHeader name="Campaign" icon={SendRoundedIcon} />}
      />
    ),
    exact: true,
  },
  {
    path: adminAsidePages.EventRequests.path,
    element: <Header headerBottom={<EventRequestsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.Sponsors.path,
    element: (
      <Header
        headerBottom={<DefaultHeader name="Sponsors" icon={HandShakeIcon} />}
      />
    ),
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.Locations.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Venue" icon={RoomOutlinedIcon} />
        }
      />
    ),
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.EventSpaces.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Event Space" icon={RoomOutlinedIcon} />
        }
      />
    ),
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.Spaces.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Spaces" icon={WorkspacesOutlinedIcon} />
        }
      />
    ),
    exact: true,
  },

  {
    path: adminAsidePages.EventSettings.items.EmailTemplates.path,
    element: <Header headerBottom={<EmailTemplatesHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Users.path,
    element: <Header headerBottom={<UsersHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Roles.path,
    element: <Header headerBottom={<RolesHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Permissions.path,
    element: <Header headerBottom={<PermissionsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Security.items.AssignSecurity.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Assign Security" icon={MdOutlineSecurity} />
        }
      />
    ),
    exact: true,
  },

  {
    path: adminAsidePages.Security.items.Attendance.path,
    element: <Header headerBottom={<AttendanceHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.Security.items.TicketSellers.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader
            name="Assign Ticket Seller"
            icon={ConfirmationNumberOutlinedIcon}
          />
        }
      />
    ),
    exact: true,
  },
  {
    path: adminAsidePages.Settings.path,
    element: <Header headerBottom={<SettingsHeader />} />,
    exact: true,
  },
  {
    path: adminAsidePages.ContactSettings.items.Organizations.path,
    element: <Header headerBottom={<OrganizationsHeader />} />,
    exact: true,
  },
  {
    path: authPages.NoPermission.path,
    element: <Header headerBottom={<></>} />,
    exact: true,
  },
  {
    path: InnerPages.Profile.path,
    element: <Header headerBottom={<ProfileHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.ContactGroupContacts.path,
    element: <Header headerBottom={<ContactGroupContactsHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.ScheduleOrder.path,
    element: <Header headerBottom={<ScheduleOrderHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.EventInvitations.path,
    element: <Header headerBottom={<EventInvitationsHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.EventBundle.path,
    element: <Header headerBottom={<EventBundleHeader />} />,
    exact: true,
  },

  {
    path: InnerPages.EventInvitations.path,
    element: <Header headerBottom={<EventInvitationsHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.SharedEvents.path,
    element: <Header headerBottom={<SharedEventsHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.SharedContacts.path,
    element: <Header headerBottom={<SharedContactsHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.SharedContacts.path,
    element: <Header headerBottom={<CompaniesHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.PlanOptions.path,
    element: (
      <Header headerBottom={<PlansHeader name="Subscription Plan Options" />} />
    ),
    exact: true,
  },

  {
    path: commonPages.Notifications.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader
            name="Notifications"
            icon={NotificationsNoneOutlinedIcon}
          />
        }
      />
    ),

    exact: true,
  },

  {
    path: subscribers.items.Companies.path,
    element: <Header headerBottom={<CompaniesHeader />} />,
    exact: true,
  },
  {
    path: subscribers.items.Vendors.path,
    element: (
      <Header
        headerBottom={<DefaultHeader name="Vendors" icon={StorefrontIcon} />}
      />
    ),
    exact: true,
  },
  {
    path: subscribers.items.Plans.path,
    element: (
      <Header headerBottom={<PlansHeader name="Subscription Plans" />} />
    ),
    exact: true,
  },
  {
    path: subscribers.items.SponsorTypes.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Sponsor types" icon={StarBorderTwoToneIcon} />
        }
      />
    ),
    exact: true,
  },
  {
    path: subscribers.items.ServiceTypes.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Service types" icon={StarBorderTwoToneIcon} />
        }
      />
    ),
    exact: true,
  },
  {
    path: subscribers.items.PushNotifications.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader
            name="Push Notifications"
            icon={StarBorderTwoToneIcon}
          />
        }
      />
    ),
    exact: true,
  },

  {
    path: InnerPages.CompanyDetail.path,
    element: <Header headerBottom={<CompanyDetailHeader />} />,
    exact: true,
  },
  {
    path: InnerPages.VendorDetail.path,
    element: <Header headerBottom={<VendorDetailHeader />} />,
    exact: true,
  },
  {
    path: subscribers.items.Categories.path,
    element: <Header headerBottom={<CategoriesHeader />} />,
    exact: true,
  },
  // {
  //   path: subscribers.items.EventSpaces.path,
  //   element: <Header headerBottom={<EventSpacesHeader />} />,
  //   exact: true,
  // },
  {
    path: adminAsidePages.Ads.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Advertisement" icon={CampaignOutlinedIcon} />
        }
      />
    ),
    exact: true,
  },
  {
    path: subscribers.items.Ads.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Advertisement" icon={CampaignOutlinedIcon} />
        }
      />
    ),
    exact: true,
  },
  {
    path: subscribers.items.Banks.path,
    element: (
      <Header
        headerBottom={<DefaultHeader name="Banks" icon={AccountBalanceIcon} />}
      />
    ),
    exact: true,
  },
  {
    path: subscribers.items.AdminPaymentHistory.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader
            name="Companies payment history"
            icon={PaymentsOutlinedIcon}
          />
        }
      />
    ),
    exact: true,
  },
  {
    path: subscribers.items.Subscribers.path,
    element: (
      <Header
        headerBottom={<DefaultHeader name="Subscribers" icon={FiUsers} />}
      />
    ),
    exact: true,
  },
  {
    path: InnerPages.PaymentHistory.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Payment history" icon={PaymentsOutlinedIcon} />
        }
      />
    ),
    exact: true,
  },
  {
    path: InnerPages.ForeignTransactionHistory.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader
            name="Foreign Transaction history"
            icon={PaymentsOutlinedIcon}
          />
        }
      />
    ),
    exact: true,
  },
  {
    path: InnerPages.EventAttendance.path,
    element: (
      <Header
        headerBottom={
          <DefaultHeader name="Event Attendance" icon={RemoveRedEyeIcon} />
        }
      />
    ),
    exact: true,
  },
  {
    path: commonPages.SubscriptionExpired.path,
    element: <Header headerBottom={<></>} />,
    exact: true,
  },
];

export { adminHeaders };
